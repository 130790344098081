import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    config,
} from 'Redux/modules/trainer/measurements/actions';
import { list as listUsers } from 'Redux/modules/trainer/users/actions';

export default connect(
    state => ({
        config: state.trainerMeasurements.config,
    }),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            config: dispatch(config),
            listUsers: dispatch(listUsers),
        },
    }),
)(Component);