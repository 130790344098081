import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_MEASUREMENTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/measurements/Editor';
import { TAB_MEASUREMENTS } from 'Components/pages/trainer/UsersManage/component';

export default class TrainerMeasurementsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
            config: PropTypes.func.isRequired,
        }).isRequired,
        measurement: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }
    
    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { measurement, location, history } = this.props;

        return (
            <StyledComponent
                className="trainer-measurements-manage"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={measurement}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: (measurement && measurement.data && measurement.data.user.id) }, { tab: TAB_MEASUREMENTS }),
                        label: 'Pomiary',
                    }, {
                        to: withVariables(TRAINER_MEASUREMENTS_MANAGE.path, { id: measurement && measurement.data && measurement.data.id }),
                        label: `Pomiar adepta: ${(measurement && measurement.data && getFullName(measurement.data.user, 'nameSurname').label)}`,
                    }]}
                >
                    {measurement && measurement.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Edytuj pomiar"
                            controls={[{
                                visible: true,
                                key: 'return',
                                label: 'Powrót do pomiarów',
                                onClick: () => history.push(
                                    withVariables(TRAINER_ADEPTS_MANAGE.path, { id: (measurement && measurement.data && measurement.data.user.id) }, { tab: TAB_MEASUREMENTS }),
                                ),
                                style: 'gradient',
                                layout: 'wide',
                            }]}
                            tabs={[{
                                key: 'data',
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={measurement.data}
                                        predefinedState={measurement.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}

