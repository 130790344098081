import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReactSVG } from 'react-svg';

import {
    TRAINER_ADEPTS_MANAGE,
} from 'Consts/routes';
import { MEASUREMENTS_CONFIG } from 'Consts/measurementConfig';

import { withVariables } from 'Utils/string';
import { toApiFormat } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layout/Spinner';
import Button from 'Components/layout/Button';
import ElementEditor from 'Components/layout/panel/ElementEditor';
import DatePicker from 'Components/forms/DatePicker';

export default class TrainerMeasurementsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            config: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        config: PropTypes.object,
        predefinedState: PropTypes.object.isRequired,
    };
    static defaultProps = {
        data: null,
        config: {},
    };

    state = {
        formState: this.props.data || {},  //eslint-disable-line react/destructuring-assignment
    };

    componentDidMount = () => {
        const { data, actions, predefinedState } = this.props;
        
        actions.config({ userId: data?.user?.id || predefinedState?.userId });
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...data,
                },
            }));
        }
    }

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history, predefinedState } = this.props;

        let data = {
            additionals: {},
        };

        for(let index in formState) {
            if(index.startsWith('additionals_')) {
                data.additionals[index.replace('additionals_', '')] = formState[index];
            } else {
                data[index] = formState[index];
            }
        }

        return actions.create({
            ...data,
            userId: predefinedState.userId,
            measureDate: formState.measureDate
                ? toApiFormat(formState.measureDate, 'datetime', true)
                : undefined,
        })
            .then(response => {
                history.push(
                    withVariables(
                        TRAINER_ADEPTS_MANAGE.path,
                        { id: response.params.userId },
                        { tab: 'measurements' }
                    )
                );
            });
    }

    onUpdate = formState => {
        const { actions, data } = this.props;

        let tmp = {
            additionals: {},
        };

        for(let index in formState) {
            if(index.startsWith('additionals_')) {
                tmp.additionals[index.replace('additionals_', '')] = formState[index];
            } else {
                tmp[index] = formState[index];
            }
        }

        return actions.update({
            ...formState,
            ...tmp,
            id: data.id,
            measureDate: formState.measureDate
                ? toApiFormat(formState.measureDate, 'datetime', true)
                : undefined,
        });
    }

    renderMeasurementConfigBoxes = ({ fieldsConfig }) => {
        let tmp = [];
        for (let index in fieldsConfig) {
            let icon = require('../../../../theme/images/icons/' + fieldsConfig[index].icon);

            tmp.push({
                isVisible: true,
                type: 'input',
                name: MEASUREMENTS_CONFIG[index] ? index : 'additionals_' + index,
                label: MEASUREMENTS_CONFIG[index] ? MEASUREMENTS_CONFIG[index] : fieldsConfig[index].name,
                units: fieldsConfig[index].units,
                position: fieldsConfig[index].enabled ? 'default' : 'hidden',
                boxContent: {
                    imageSrc: icon,
                },
                inputProps: {
                    type: 'number',
                    styleVersion: 2,
                    placeholder: '...',
                },
            });
        }

        return tmp;
    }

    render() {
        const { location, history, config } = this.props;
        const { formState } = this.state;

        if (!formState || !config || !config.data) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="trainer-measurements-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    layout="box"
                    header={(
                        <div className="measurements-editor-header">
                            <div className="measurements-editor-header-col col-left">
                                <h2 className="measurements-editor-header-headline">
                                    Twój pomiar
                                </h2>
                                <div className={'measurements-editor-calendar'}>
                                    <ReactSVG
                                        className="box-icon"
                                        width={15}
                                        beforeInjection={svg => {
                                            svg.classList.add('icon-calendar');
                                            svg.firstElementChild.setAttribute('stroke-width', '1.75');
                                        }}
                                        src={require('Theme/images/icons/calendar.svg')}
                                    />
                                    <DatePicker
                                        name="measurements-datepicker"
                                        className='measurements-editor-header-datepicker'
                                        value={moment(formState.measureDate).toDate()}
                                        styleVersion={2}
                                        onChange={(date) => this.setState({
                                            formState: {
                                                ...formState,
                                                measureDate: date.value,
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="measurements-editor-header-col col-right">
                                <Button
                                    className="measurements-editor-header-button"
                                    onClick={() => this.onSubmit}
                                    type='submit'
                                    form='editor'
                                    style='hollow'
                                >
                                    Zapisz
                                </Button>
                            </div>
                        </div>
                    )}
                    history={history}
                    location={location}
                    styleVersion={2}
                    forms={[{
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        layout: 'box',
                        submitVisible: false,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: this.renderMeasurementConfigBoxes(config.data),
                    }]}
                />
            </StyledComponent>
        );
    }
}