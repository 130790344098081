import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .measurements-editor-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .measurements-editor-header-col  {
                display: flex;
                align-items: center;

                &.col-left {
                    justify-content: flex-start;
                }
                
                &.col-right {
                    justify-content: flex-end;
                }

                .measurements-editor-header-headline {
                    font-size: 1.5em;
                    color: ${variables.dpc_fontDark};
                    font-weight: 400;
                    margin-right: 2em;
                }

                .measurements-editor-calendar {
                    display: flex;
                    padding: .6em 1.25em;

                    .box-icon {
                        display: flex;
                        align-items: center;
                        margin-right: .75em;

                            div {
                                .icon-calendar {
                                    width: 1.5em;
                                    height: auto;
                                }
                            }
                    }
                }

                .measurements-editor-header-datepicker {
                    margin-left: 0em;
                }

                .measurements-editor-header-button {

                }
            }
        }

        .admin-element-editor {
            .editor-wrapper {
                .forms-wrapper {
                    width: 100% !important;
                    padding-right: 0 !important;

                    .form-wrapper {
                        padding: 0;
                        border: none;

                        .form-elements {
                            .box {
                                .box-body {
                                    .box-input {
                                        .form-element {
                                            .input-external-wrapper {
                                                .input-internal-wrapper {
                                                    border: none;

                                                    input {
                                                        color: #D83143;

                                                        height: 1.25em;
                                                        width: 100%;

                                                        border-radius: 0em;
                                                        border-top: none;
                                                        border-left: none !important;
                                                        border-right: none;
                                                        border-bottom: 1px solid #D83143;
                                                        
                                                        font-size: 1.5em;
                                                        font-weight: 600;
                                                        padding: 0;
                                            
                                                        &:focus {
                                                            color: #D83143;
                                                            border-color: #D83143;
                                                        }

                                                        &::placeholder {
                                                            color: #979797;
                                                        }
                                                    
                                                        &[disabled=""] {
                                                            border-bottom: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                .data-wrapper {
                    display: none;
                }
            }
        }
                
            .data-wrapper {
                display: none;
            }

        @media (max-width: ${variables.tabletS}) {
            .measurements-editor-header {
                flex-direction: column;
                margin-top: 1em;

                .col-left {
                    margin-bottom: 2em;
                }

                .col-right {
                    width: 100%;

                    .component-button {
                        width: 100%;

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    `;
